import styled from 'styled-components';

export const Container = styled.div`
  & .stamped-summary-photos.stamped-summary-photos-container {
    display: none !important;
  }

  & .stamped-widget-logo > img {
    width: 70px;
    margin: auto;
    display: block;
  }

  & .stamped-summary {
    display: flex;
    flex-direction: row;

    & .summary-overview {
      margin-left: auto;
    }

    & .stamped-summary-ratings {
      margin-right: auto;
    }
  }

  @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
    & .stamped-widget-logo {
      margin-bottom: 20px;
      width: 100%;
    }
    & .stamped-widget-logo > img {
      width: 100px;
    }
    & .stamped-summary {
      flex-direction: column;
    }
  }
  & .stamped-container .stamped-summary-actions {
    display: none !important;
  }
`;
